import { getKeywordCookie } from '@/features/roomster-app/cookies/cookie-keyword';
import { getReferralCookie } from '@/features/roomster-app/cookies/cookie-referral';

const baseAppFlyerLink = 'https://roomster.onelink.me/bzUF';
const baseAppFlyerQuery = 'af_xp=custom';

type DeepLinks = 'keyword' | 'referral' | 'path';

type LinkName =
  'mobileTopBanner' |
  'mobileLoginButton' |
  'mobileMenuListYourSpaceButton' |
  'mobileListingUserAvatar' |
  'mobileListingBookmark' |
  'mobileListingReport' |
  'mobileSearchAddListingButton' |
  'mobileListingMessageButton' |
  'mobileMessageButton' |
  'mobileListPlace' |
  'mobileFindPlace' |
  'mobileMailbox' |
  'mobileContactUs' |
  'mobileMegaphone' |
  'mobileBookmark' |
  'mobileSupport' |
  'mobileHelpCenter' |
  'mobileSettings';

type MediaSource = 'mobile';

interface AppFlyerData {
  mobile: Record<LinkName, AppFlyerHref>
}

interface AppFlyerHref {
  pathname: string;
  query: string;
}

const deepLinks: Record<DeepLinks, string> = {
  keyword: 'deep_link_sub2',
  path: 'deep_link_value',
  referral: 'deep_link_sub1',
} as const;

export default function getAppFlyerLink(mediaSource: MediaSource, linkName: LinkName): AppFlyerHref {
  const referralCookie = getReferralCookie();
  const referralQuery = referralCookie ? `&${deepLinks.referral}=${encodeURIComponent(referralCookie)}` : `&${deepLinks.referral}=`;

  const keywordCookie = getKeywordCookie();
  const keywordQuery = keywordCookie ? `&${deepLinks.keyword}=${encodeURIComponent(keywordCookie)}` : `&${deepLinks.keyword}=`;

  // https://roomster.onelink.me/bzUF?af_xp=custom&pid=Mobile&c=Listing%20Message%20Button&deep_link_sub1=&deep_link_value=login

  const appFlyerLinks: AppFlyerData = {
    mobile: {
      mobileBookmark: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Bookmarks${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileContactUs: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Contact%20Us${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileFindPlace: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=HP%20Find-a-place%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileHelpCenter: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Help%20Center${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingBookmark: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20Bookmark${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingMessageButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20Message%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingReport: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20Report${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListingUserAvatar: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Listing%20User%20Avatar${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileListPlace: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=HP%20List-a-place%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileLoginButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Login%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMailbox: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Mailbox${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMegaphone: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Megaphone${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMenuListYourSpaceButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Menu%20List-your-space%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileMessageButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Search%20Message%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileSearchAddListingButton: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Search%20Add-listing%20Button${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileSettings: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Support${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileSupport: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Bottom%20Nav%20Support${referralQuery}${keywordQuery}&${deepLinks.path}=login` },
      mobileTopBanner: { pathname: baseAppFlyerLink, query: `${baseAppFlyerQuery}&pid=Mobile&c=Top%20Banner${referralQuery}${keywordQuery}` },
    },
  } as const;

  return appFlyerLinks[mediaSource][linkName];
}
